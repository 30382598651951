Handlebars.registerHelper('ifCond', function (v1: any, operator, v2: any, options) {
    switch (operator) {
        // case '==':
        //     return (v1 == v2) ? options.fn(this) : options.inverse(this);
        case '===':
            return (v1 === v2) ? options.fn(this) : options.inverse(this);
        // case '!=':
        //     return (v1 != v2) ? options.fn(this) : options.inverse(this);
        case '!==':
            return (v1 !== v2) ? options.fn(this) : options.inverse(this);
        case '<':
            return (v1 < v2) ? options.fn(this) : options.inverse(this);
        case '<=':
            return (v1 <= v2) ? options.fn(this) : options.inverse(this);
        case '>':
            return (v1 > v2) ? options.fn(this) : options.inverse(this);
        case '>=':
            return (v1 >= v2) ? options.fn(this) : options.inverse(this);
        case '&&':
            return (v1 && v2) ? options.fn(this) : options.inverse(this);
        case '||':
            return (v1 || v2) ? options.fn(this) : options.inverse(this);
        default:
            return options.inverse(this);
    }
});

Handlebars.registerHelper('FullName', function (person) {
    return person.first_name + ' ' + person.last_name;
});

Handlebars.registerHelper('Address', function (location, padding, paddingLine1) {
    if (isNaN(padding)) {
        padding = 0;
    }
    if (isNaN(paddingLine1)) {
        paddingLine1 = padding;
    }
    let pad = Array(padding + 1).join(' ');
    let firstpad = Array(paddingLine1 + 1).join(' ');

    return new Handlebars.SafeString(
        firstpad + location.line1 + '\n'
        + (location.line2 ? pad + location.line2 + '\n' : '')
        + pad + location.city + ', ' + location.state + ' ' + location.zip
    );
});

Handlebars.registerHelper('Location', function (location) {
    return new Handlebars.SafeString(
        location.line1 + '<br>'
        + (location.line2 ? location.line2 + '<br>' : '')
        + location.city + ', ' + location.state + ' ' + location.zip
    );
});

Handlebars.registerHelper('BookingColor', (status) => {
    let color = '';
    if (status === 'available') {
        color = 'blue lighten-2';
    } else if (status === 'pending') {
        color = 'yellow lighten-2';
    } else if (status === 'confirmed') {
        color = 'green accent-3';
    } else if (status === 'cancelled') {
        color = 'red lighten-1';
    } else if (status === 'reported') {
        color = 'pink accent-2';
    } else if (status === 'completed') {
        color = 'purple accent-2';
    }
    return color;
});

Handlebars.registerHelper('AssignmentColor', (status) => {
    let color = '';
    if (status === 'requested') {
        color = 'yellow lighten-2';
    } else if (status === 'confirmed') {
        color = 'green accent-3';
    } else if (status === 'preempted') {
        color = 'orange lighten-2';
    } else if (status === 'declined') {
        color = 'orange lighten-2';
    } else if (status === 'cancelled') {
        color = 'red lighten-1';
    } else if (status === 'completed') {
        color = 'purple accent-2';
    }
    return color;
});

Handlebars.registerHelper('DTFormat', (date, format) => {
    let dateFormatter = new DateFormatter();
    let iDate = new Date();
    iDate.setYmdHis(date);
    return dateFormatter.formatDate(iDate, format);
});

Handlebars.registerHelper('Nl2br', text => {
    let nl2br = (text + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + '<br>' + '$2');
    return new Handlebars.SafeString(nl2br);
});

Handlebars.registerHelper('Phone', (phone, replacer, format) => {
    if (phone.length === 10) {
        let parts = format.split(replacer);
        if (parts.length === 4) {
            phone = parts[0] + phone.slice(0, 3) + parts[1] + phone.slice(3, 6) + parts[2] + phone.slice(6, 10) + parts[3];
        } else {
            phone = 'Bad Format';
        }
    }
    return phone;
});